<template>
  <div class="range" @mouseover="hover">
    <div class="type1">
      <span>創意內容</span>
    </div>
    <div class="bbl bubble1">
      <div class="topic">Creative</div>
      <font>內容的有感與互動，都來自團隊們的創意，從品牌策略到生活結合，都可經營發想至做出非常有感的貼文</font>
    </div>
    <div class="type2">
      <span>視覺設計</span>
    </div>
    <div class="bbl bubble2">
      <div class="topic">Design</div>
      <font>如果說創意是靈魂，設計就是外在軀殼，任何事物都需要有完整的視覺，自媒體的內容則可豐富化。視覺可分為：Gif、多圖排版、靜態圖片、極短片、手繪漫畫、實拍情境。</font>
    </div>
    <div class="type3">
      <span>排程計劃</span>
    </div>
    <div class="bbl bubble3">
      <div class="topic">Plan</div>
      <font>在經營規劃的同時，團隊會與您溝通您的品牌策略，有計劃地排程何時何地的曝光，可隨時掌握品牌計劃。</font>
    </div>
    <div class="type4">
      <span>影片拍攝</span>
    </div>
    <div class="bbl bubble4">
      <div class="topic">Film</div>
      <font>影片呈獻已然成為時下經營社群媒體首當使用的素材方式，我們將影片分支如下<br>2D 小動畫<br>創意短片</font>
    </div>
    <div class="type5">
      <span>廣告投遞</span>
    </div>
    <div class="bbl bubble5">
      <div class="topic">Advertising</div>
      <font>無論經營 FB 或其他自媒體，可藉由些許的廣告經費讓內容觸及更廣。這其中包含讚廣告、貼文互動、影片觀看...等。</font>
    </div>
    <div class="type6">
      <span>Blog 文章</span>
    </div>
    <div class="bbl bubble6">
      <div class="topic">Blog</div>
      <font>為了增加網站 SEO ，針對品牌與服務官方網站內，新增了 Blog 文章：<br>1. 可加強自然搜尋優化<br>2. 強化消費者對品牌或服務的了解性</font>
    </div>
    <div class="weMedia">
      <div class="flip"></div>
      <span>We Media</span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    hover() {},
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hookIn = window.innerHeight * 0.88;
      $(".bbl").each(function(idx) {
        if (st > $(this).offset().top - hookIn) {
          $(this).addClass("active");
        } else {
          $(this).removeClass("active");
        }
      });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "../assets/scss/style.scss";

$iconHeight: 32%;
$iconWidth: $iconHeight * 0.317;
.range {
  position: relative;
  background-image: url("../assets/image/service/socialMediaManagement/path.svg");
  background-repeat: no-repeat;
  width: 85vw;
  height: 27vw;
  margin-bottom: 20%;
  @for $i from 1 to 7 {
    .type#{$i} {
      background-image: url("../assets/image/service/socialMediaManagement/type#{$i}.svg");
      background-repeat: no-repeat;
      position: absolute;
      width: $iconWidth;
      height: $iconHeight;
      display: flex;
      flex-direction: column;
      transform: scale(1);
      transition: 0.3s;
      span {
        position: absolute;
        width: 100%;
        bottom: -32%;
        @media #{$tablet} {
          bottom: -40%;
        }
        @include font(small);
        white-space: nowrap;
      }
      &:hover {
        transform: scale(1.05);
        & ~ .bubble#{$i} {
          transform: scale(1);
        }
      }
    }
    .bubble#{$i} {
      @include font(smaller);
      color: #fff;
      position: absolute;
      background-color: #73cccf;
      border-radius: 25px;
      width: 20%;
      padding: 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      transform: scale(0);
      transition: 0.3s;
      z-index: 2;
      .topic {
        @include font(small);
      }
    }
  }
  $bottomIcon: 77%;
  $topIcon: 40%;
  $bottomBubble: 90% + $iconHeight;
  $topBubble: 52% + $iconHeight;

  .type1 {
    top: 16%;
    left: 5%;
  }
  .bubble1 {
    top: 28% + $iconHeight;
    left: -2%;
  }
  .type2 {
    top: $bottomIcon;
    left: 16%;
  }
  .bubble2 {
    top: $bottomBubble;
    left: 9%;
  }
  .type3 {
    top: $topIcon;
    left: 28%;
  }
  .bubble3 {
    top: $topBubble;
    left: 21%;
  }
  .type4 {
    top: $bottomIcon;
    left: 40.5%;
  }
  .bubble4 {
    top: $bottomBubble;
    left: 33.5%;
  }
  .type5 {
    top: $topIcon;
    left: 52.5%;
  }
  .bubble5 {
    top: $topBubble;
    left: 45.5%;
  }
  .type6 {
    top: $bottomIcon;
    left: 65.5%;
  }
  .bubble6 {
    top: 90% + $iconHeight;
    left: 58.5%;
  }
  .weMedia {
    position: absolute;
    width: $iconWidth * 2;
    height: $iconHeight * 2;
    left: 76%;
    top: 5%;
    .flip {
      width: 100%;
      height: 100%;
      background-image: url("../assets/image/service/socialMediaManagement/WeMedia.png");
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotatey(-180deg);
      transition: 0.3s;
    }
    &:hover {
      .flip {
        background-image: url("../assets/image/service/socialMediaManagement/WeMediaFlip.png");
        transform: rotatey(0deg);
      }
    }
    span {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: -13%;
      @media #{$tablet} {
        bottom: 0%;
      }
      @include font(small);
      white-space: nowrap;
    }
  }
  @media #{$tablet} {
    margin-top: 30px;
    background-image: url("../assets/image/service/socialMediaManagement/pathM.svg");
    background-position: 50% 0;
    width: 100vw;
    height: 372.086vw;
    @for $i from 1 to 7 {
      $translateCalcY: translateY(calc(-21vh * #{$i} + 16% * (6 - #{$i})));
      $translateXL: translateX(-34%);
      $translateXR: translateX(-15%);
      $leftCol: 55%;
      $rightCol: 55%;
      .type#{$i} {
        transform: scale(1.05);
        width: 22%;
        height: 7.5%;
        top: ($i - 1) * 13% + 5%;
        span {
          position: relative;
          bottom: -100%;
        }
        &:hover ~ .bubble#{$i},
        & ~ .bubble#{$i}.active {
          transform: translateY(-2vh) scale(1);
        }
      }
      .bubble#{$i} {
        $bubbleWidth: 45vw;
        width: $bubbleWidth;
        transform: translateY(-2vh) scale(0);
        height: 12.5%;
        max-height: 175px;
        top: ($i - 1) * 13% + 3%;
      }
      .bubble1,
      .bubble3,
      .bubble5 {
        left: $rightCol - 6%;
      }
      .bubble2,
      .bubble4,
      .bubble6 {
        left: auto;
        right: $leftCol - 6%;
      }
      .type1,
      .type3,
      .type5 {
        left: auto;
        right: $leftCol;
      }
      .type2,
      .type4,
      .type6 {
        left: $rightCol;
      }
    }
    .weMedia {
      top: 83%;
      left: 17%;
      width: 55.7%;
      height: 17%;
    }
  }
}
</style>
