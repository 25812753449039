<template>
  <div class="socialMediaManagement">
    <header>
      <top-title title="自媒體經營" subTitle="brainstorming"></top-title>
    </header>
    <page-main-body
      title="我們致力於發想客戶創意內容經營您的自媒體！"
      subTitle="Let us build your stunning social media!"
      paragraph1="品牌行銷的過程中皆出自時代性的風口，許多客戶不理解自己方向為何? 經由我們團隊的引導，激發出客戶對品牌創意的重視，打造屬於每一個品牌的定位與價值，是我們的核心任務。">
    </page-main-body>
    <h2>自媒體經營的範圍：Facebook、IG、品牌部落格</h2>
    <h3>Including Facebook, Instagram, brand blog, and etc.</h3>
    <range/>
    <div class="banner">
      <h2>經營品牌內網誌的四大優點</h2>
      <h3>Four advantages of operating a brand within the brand</h3>
    </div>
    <div class="advantages">
      <div class="advanCol col-left">
        <span class="advan">長遠深耕與商品呼應</span>
        <span class="advan">突破品牌溫度</span>
      </div>
      <div class="mac"></div>
      <div class="advanCol col-right">
        <span class="advan">增加 SEO 搜尋量體</span>
        <span class="advan">隨時掌握最新潮流</span>
      </div>
    </div>
    <h2 style="width:90vw">近年來我們已協助客戶完成以下里程碑</h2>
    <div class="circles">
      <counting-circle v-if="circleShow1"
        :ID="1"
        :count="7689860"
        color="red"
        text1="Posting Like"
        text2="貼文讚數"
      />
      <counting-circle v-if="circleShow2"
        :ID="2"
        :count="5840"
        color="blue"
        text1="Post"
        text2="貼文數"
      />
      <counting-circle v-if="circleShow3"
        :ID="3"
        :count="98241"
        color="red"
        text1="Like"
        text2="粉絲團讚數"
      />
      <counting-circle v-if="circleShow4"
        :ID="4"
        :count="20326514"
        color="blue"
        text1="Comment"
        text2="留言數"
      />
    </div>
    <div class="view-relate" style="margin-top:400px" @click="goto">
      觀看相關案例
    </div>
    <footer-view />
  </div>
</template>

<script>
import topTitle from "@/components/topTitle.vue";
import pageMainBody from "@/components/pageMainBody.vue";
import range from "@/components/range.vue";
import countingCircle from "@/components/countingCircle.vue";
import footerView from "@/components/footerView.vue";

export default {
  name: "filming",
  components: {
    topTitle,
    pageMainBody,
    range,
    countingCircle,
    footerView
  },
  data() {
    return {
      circleShow1: false,
      circleShow2: false,
      circleShow3: false,
      circleShow4: false
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    this.$parent.inPage = true;
  },
  destroyed() {
    this.$parent.inPage = false;
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    circleShow1() {
      $(".view-relate").css({ margin: "60px 0" });
    }
  },
  methods: {
    handleScroll() {
      var st = window.pageYOffset || document.documentElement.scrollTop;
      var hook = window.innerHeight * 0.7;
      var vm = this;
      if (st > $(".advan").offset().top - hook) {
        $(".advan").each(function(idx) {
          $(this).addClass("pop" + (idx + 1));
        });
      }
      if (st > $(".circles").offset().top - hook) {
        if (window.innerWidth < 768) {
          vm.circleShow1 = true;
        } else {
          vm.circleShow1 = true;
          vm.circleShow2 = true;
          vm.circleShow3 = true;
          vm.circleShow4 = true;
        }
        $(".countingCircle").each(function(idx) {
          if (st > $(this).offset().top - hook * 0.7) {
            vm["circleShow" + (idx + 2)] = true;
          }
        });
      }
    },
    goto() {
      this.$router.push({ name: 'CaseStudies', params: { caseType: 'socialMediaManagement' }})
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

header {
  background-image: url("../../assets/image/service/socialMediaManagement/topBanner.jpg");
}

.socialMediaManagement {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    @include font(medium);
    margin-top: 40px;
    margin-bottom: 0;
  }
  h3 {
    @include font(small);
    font-weight: 300;
    margin: 0;
  }
  .banner {
    h2 {
      margin: 0;
    }
    background-image: url("../../assets/image/service/socialMediaManagement/banner.jpg");
    background-attachment: fixed;
    background-size: cover;
    width: 100vw;
    height: 20vw;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media #{$tablet} {
      background-image: url("../../assets/image/service/socialMediaManagement/bannerM.jpg");
      background-size: cover;
      height: 30vw;
    }
  }
  .advantages {
    display: flex;
    width: 100vw;
    margin-top: 100px;
    @media #{$phone} {
      margin-top: 20px;
    }
    align-items: center;
    .mac {
      flex: 4;
      width: 40vw;
      height: 30vw;
      background-image: url("../../assets/image/service/socialMediaManagement/mac.jpg");
      background-size: contain;
      background-repeat: no-repeat;
      order: 3;
    }
    .advanCol {
      flex: 3;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      span {
        display: flex;
        align-items: center;
        text-align: left;
        width: 260px;
        margin: 48px 0;
        white-space: nowrap;
        @include font(small);
        &::before {
          content: "";
          display: block;
          background-image: url("../../assets/image/service/socialMediaManagement/check.svg");
          background-repeat: no-repeat;
          $checkBoxSize: 30px;
          width: $checkBoxSize;
          height: $checkBoxSize * 0.836;
        }
      }
      &.col-left {
        order: 2;
        align-items: flex-end;
      }
      &.col-right {
        order: 4;
      }
    }
    @media #{$tablet} {
      flex-direction: column;
      .mac {
        order: 1;
        width: 100vw;
        height: 76.5vw;
        margin-bottom: 20px;
      }
      .advanCol {
        justify-content: center;
        transform: translateX(20px);
        @media #{$phone} {
          transform: translateX(0);
          width: 140px;
        }
        span {
          margin-top: 4%;
          margin-bottom: 0;
          @media #{$phone} {
            width: 175px;
          }
        }
        &.col-left,
        &.col-right {
          align-items: center;
        }
      }
    }
  }
  .circles {
    width: 84vw;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 30px;
    @media #{$phone} {
      width: 100vw;
    }
  }
  .view-relate {
    cursor: pointer;
    width: 40vw;
    @media #{$phone} {
      width: 75vw;
    }
    border: 1px solid #707070;
    margin-top: 60px;
    padding: 1% 3%;
    transition: 0.3s;
    @include font(medium);
    font-weight: 300;
    &:hover {
      background-color: #707070;
      color: #fff;
    }
  }
}

.advan {
  opacity: 0;
  transform: translateY(25px);
  @for $i from 1 to 5 {
    &.pop#{$i} {
      opacity: 1;
      transform: translate(0);
      animation: pop#{$i} 2s;
      @keyframes pop#{$i} {
        0% {
          opacity: 0;
          transform: translateY(25px);
        }
        #{($i - 1) * 25%} {
          opacity: 0;
          transform: translateY(25px);
        }
        #{$i * 25%} {
          opacity: 1;
          transform: translate(0);
        }
      }
    }
  }
}
</style>
