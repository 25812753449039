<template>
  <div class="countingCircle" :class="[color]">
    <div class="circle">
      <div class="mask"></div>
      <span>{{text1}}</span>
      <span>{{text2}}</span>
    </div>
    <font :class="[`count${ID}`]">{{count}}</font><font>+</font>
  </div>
</template>

<script>
export default {
  props: {
    ID: Number,
    count: Number,
    color: String,
    text1: String,
    text2: String
  },
  mounted() {
    setTimeout(() => {
      $(".count" + this.ID).each(function() {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text()
            },
            {
              duration: 3000,
              easing: "swing",
              step: function(now) {
                $(this).text(Math.ceil(now));
              }
            }
          );
      });
    }, 100);
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/style.scss";

.countingCircle {
  .circle {
    $borderWidth: 17px;
    position: relative;
    $circleSize: 250px;
    width: $circleSize;
    height: $circleSize;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 60px 30px;
    transform: scale(0.78);
    @media #{$phone} {
      margin: 40px 0;
    }
    &::before,
    &::after {
      margin: 0;
      content: "";
      display: block;
      position: absolute;
      $borderWidth: 17px;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      border: $borderWidth solid;
    }
    &::before {
      transform: rotate(45deg) translateX(0px);
      z-index: 2;
      animation: rightHalf 3s linear;
      @keyframes rightHalf {
        0% {
          z-index: 0;
        }
        49% {
          z-index: 0;
        }
        50% {
          z-index: 2;
        }
      }
    }
    &::after {
      transform: rotate(45deg);
      animation: leftHalf 3s linear;
      @keyframes leftHalf {
        0% {
          opacity: 0;
        }
        50% {
          opacity: 0;
        }
        50.1% {
          opacity: 1;
        }
      }
    }
    .mask {
      position: absolute;
      top: -$borderWidth - 10px;
      right: -$borderWidth - 10px;
      width: calc(50% + #{$borderWidth} + 10px);
      height: calc(100% + #{$borderWidth} * 2 + 10px);
      background-color: #fff;
      transform-origin: 0 50%;
      z-index: 1;
      border-radius: 0 100% 100% 0;
      animation: rot 3s linear;
      @keyframes rot {
        0% {
          transform: rotate(0deg);
        }
        25% {
          transform: rotate(90deg);
        }
        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    span {
      @include font(medium);
      font-weight: 300;
      z-index: 1;
    }
  }
  font {
    @include font(medium);
    font-weight: 300;
    z-index: 3;
  }
  &.red {
    .circle::before {
      border-top-color: $mainRed;
      border-right-color: $mainRed;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    .circle::after {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: $mainRed;
      border-left-color: $mainRed;
    }
    font {
      color: $mainRed;
    }
  }
  &.blue {
    .circle::before {
      border-top-color: $mainBlue;
      border-right-color: $mainBlue;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
    .circle::after {
      border-top-color: transparent;
      border-right-color: transparent;
      border-bottom-color: $mainBlue;
      border-left-color: $mainBlue;
    }
    font {
      color: $mainBlue;
    }
  }
}
</style>
